<template>
  <div>
      退出中...
  </div>
</template>

<script>
  import {logoutUser} from '@/plugins/auth'
  export default {
      name: 'logout',
      created() {
          logoutUser()
          this.$router.push({name: 'login'})
          this.$message.success('退出登录成功!')
      }
  }
</script>
